<template>
  <div>
    <hr class="mb-1" />
    <p class="pb-3 text-gray-500 font-semibold">Insurance Type</p>
    <div class="grid sm:grid-cols-5 gap-4 mb-5">
      <InsuranceType v-for="(company, i) in companies" :key="i"  :selected="selectedInsuranceType === i" :title="company.name" @on-selected="setSetectedInsuranceType(i)" />
    </div>
    <hr class="mb-4" />
    <PaymentInfoForm
      @on-cancel="emit('cancel')"
      @on-success="handleSuccess"
      :index="selectedInsuranceType"
    />
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { InsuranceType, PaymentInfoForm } from "./insurance";

// InsuranceType, LeadwayForm, MyCoverGeniusForm, SoverignTrustForm AiicoForm, CustodianForm,


export default{
  components: {
    InsuranceType,
    PaymentInfoForm,
  },
  setup(props, { emit }) {
    const state = reactive({});
    const store = useStore();
    onMounted(() => {
      axios.get(`${process.env.VUE_APP_API_URL}payments/plans`)
      .then((e) => {
        console.log(e.data);
        if (e.data.body.length > 0 ) {
          setSetectedInsuranceType(0);
        }
        store.commit({
          type: "setInsuranceCompanies",
          insuranceCompanies: e.data.body,
        })
      })
    });
    const handleSuccess = () => {
      Swal.fire("Success", "Your purchase was successful", "success");
      emit("success");
    };

    const setSetectedInsuranceType = (index) => {
      store.commit({
        type: "setSelectedInsuranceType",
        index: index,
      });
    };

    return {
      state,
      emit,
      handleSuccess,
      companies: computed(() => store.state.insuranceCompanies),
      selectedInsuranceType: computed(() => store.state.selectedInsuranceType),
      setSetectedInsuranceType
    };
  },
};
</script>

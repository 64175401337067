export default {
  types: [
    { name: "Third Party", value: "third_party" },
    { name: "Comprehensive", value: "comprehensive" },
  ],

  idTypes: [
    "National ID",
    "International Passport",
    "Drivers License",
    "Voters Card",
  ],

  titles: [
    "Chief",
    "Clergyman",
    "Doctor",
    "Elder",
    "Engr",
    "Excellency",
    "Honorable",
    "Master",
    "Miss",
    "Mr",
    "Mrs",
    "Professor",
  ],

  vehicleCategories: ["Bus", "Car", "Jeep - Suv", "Truck"],

  gender: ["male", "female"],

  maritalStatus: ["single", "married", "divorced"],

  states: [
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Ogun",
    "Ondo",
    "Rivers",
    "Bauchi",
    "Benue",
    "Borno",
    "Bayelsa",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "Federal Capital Territory",
    "Gombe",
    "Jigawa",
    "Oyo",
    "Imo",
    "Kaduna",
    "Kebbi",
    "Kano",
    "Kogi",
    "Osun",
    "Sokoto",
    "Plateau",
    "Taraba",
    "Yobe",
    "Zamfara",
    "Lagos",
    "Katsina",
    "Kwara",
    "Nasarawa",
    "Niger",
    "Abia",
  ],
};

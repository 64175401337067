<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <!-- <input
        type="text"
        class="w-full px-4 text-app-gray rounded-md border border-gray-200 mb-8 h-12"
        placeholder="Enter OTP sent"
        v-model="formData.otp"
        required
      /> -->

      <div class="form-group">
        <label for="email" class="form-label font-light">Email</label>
        <input
          type="text"
          id="email"
          class="form-input"
          placeholder="Enter Email"
          required
          v-model="formData.email"
        />
      </div>

      <div class="form-group">
        <label for="phone" class="form-label">Phone Number</label>
        <input
          type="text"
          id="phone"
          class="form-input"
          placeholder="+234"
          required
          v-model="formData.phone"
        />
      </div>

      <!-- <input
        type="text"
        class="w-full px-4 text-app-gray rounded-md border border-gray-200 mb-8 h-12"
        placeholder="Re-type your email address"
        v-model="formData.confirmEmail"
        required
      />

      <input
        type="password"
        class="w-full px-4 text-app-gray rounded-md border border-gray-200 mb-8 h-12"
        placeholder="Enter your password"
        v-model="formData.password"
        required
      />

      <input
        type="password"
        class="w-full px-4 text-app-gray rounded-md border border-gray-200 mb-8 h-12"
        placeholder="Re-type your password"
        v-model="formData.conformPassword"
        required
      /> -->

      <button
        type="submit"
        :class="`w-full btn px-2 rounded-md text-white text-md font-semibold w-32 h-12 ${
          state.loading
            ? 'cursor-not-allowed bg-chip-red'
            : 'bg-cerise-red hover:bg-red-700 '
        }`"
        :disabled="state.loading"
      >
        {{ state.loading ? "Please wait..." : "Save" }}
      </button>
    </form>
  </div>
</template>

<script>
import { reactive } from "vue";
// import { event } from "vue-gtag";
import axios from "axios";

export default {
  setup(props, { emit }) {
    const state = reactive({
      loading: false,
    });

    const formData = reactive({
      otp: "",
      email: "",
      confirmEmail: "",
      password: "",
      confirmPassword: "",
    });

    const handleSubmit = async () => {
      state.loading = true;
      console.log("YYYY");
      // event("reminder_request", {
      //   event_category: "form_submit",
      //   event_label: `Reminder request: ${formData.email} - ${formData.phone}`,
      //   count: 1,
      // });
      await axios.get("https://jsonplaceholder.typicode.com/posts/1"); // Just for simulation
      state.loading = false;
      emit("submit");
    };

    return {
      state,
      formData,
      handleSubmit,
      emit,
    };
  },
};
</script>

import axios from "axios";

const BASE_URL = process.env.VUE_APP_API_URL;

export const postData = async (path, payload = {}, options = {}) => {
  try {
    const { data } = await axios.post(`${BASE_URL}${path}`, payload, options);

    return { error: null, data };
  } catch (error) {
    const d = error?.response?.data;
    return { error, data: d };
  }
};

export const getData = async (path, params = {}) => {
  try {
    const { data } = await axios.get(`${BASE_URL}${path}`, { params });

    return { error: null, data };
  } catch (error) {
    const d = error?.response?.data;
    return { error, data: d };
  }
};

export const buyInsurance = async (company, type, details) => {
  const payload = {
    company,
    insurance_type: type,
    details,
  };

  const response = postData("api/insurance/buy-insurance", payload);

  return response;
};

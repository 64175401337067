<template>
  <div class="bg-white py-2">
    <div class="container mx-auto py-2 px-4 sm:px-2 lg:px-6">
      <div class="grid grid-cols-1 md:grid-cols-2">
        <div
          class="flex justify-center md:justify-start items-center mb-4 md:mb-0"
        >
          <router-link to="/">
            <img
              src="/images/pd-logo.png"
              alt="tmr-logo.png"
              title="TMR Logo"
              class="max-h-10"
            />
          </router-link>
          <h1 class="ml-2 font-bold text-xl leading-tight">
            <router-link to="/">PlateDetect</router-link>
          </h1>
        </div>
        <div class="flex justify-center md:justify-end items-center">
          <div class="flex flex-col md:flex-row text-tuna text-sm text-right">
            <div class="flex md:mr-4 mb-2 md:mb-0">
              <img
                src="/images/call.svg"
                alt="call icon"
                title="TMR Logo"
                class="w-5 h-5 mr-1"
              />
              08129937210, 08129937211
            </div>
            <div class="flex">
              <img
                src="/images/mail.svg"
                alt="mail icon"
                title="TMR Logo"
                class="w-5 h-5 mr-1"
              />
              support@platedetect.com
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>

import dayjs from "dayjs";

export const numberFormat = (number, p = 2) => {
  if (!number) return '0';

  return parseFloat(number).toFixed(p).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export const dateFormat = (date, format = 'MMM DD, YYYY HH:mm') => {
  if (!date) return '';

  return dayjs(date).format(format);
}
<template>
  <div class="h-full">
    <div
      style="
        background: url('/images/bg-image.svg');
        background-size: cover;
        height: 100%;
      "
      class="overflow-y-scroll"
    >
      <div class="container mx-auto">
        <TitleBar
          title="Pay for your vehicle insurance "
          subtitle=""
        />

        <BuyInsurance />

        <p class="text-center mt-4">
          <router-link to="/" class="underline text-white">Go Home</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { BuyInsurance } from "@/components/insurance";
import { TitleBar } from "@/components/home";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';
import { computed, reactive } from "vue";

export default {
  components: {
    TitleBar,
    BuyInsurance,
  },
  setup() {
    const state = reactive({
      resultData: {},
      showResult: false,
      showModal: false,
      currentModal: "",
      foo: false,
    });

    const handleResult = (data) => {
      state.resultData = data;
      state.showResult = true;
    };

    const backIcon = computed(() => faChevronLeft);

    const handleCloseModal = () => {
      state.currentModal = "";
    };

    const handlePurchaseAction = ({ type, value }) => {
      if (type === "link") {
        window.open(value, "_blank");
        return;
      }
      console.log(value);

      state.currentModal = value;
    };

    const showMainSection = () => {
      // screenview({
      //   app_name: 'Platedetect',
      //   screen_name: 'Main Section',
      // });
      state.showResult = false;
    };

    const handleRenewalSubmitted = () => {
      state.showModal = false;
      Swal.fire(
        "Success",
        "Thank you. You will be sent a reminder before your renewal is due",
        "success",
      );
    };

    return {
      state,
      handleResult,
      backIcon,
      handleCloseModal,
      handlePurchaseAction,
      showMainSection,
      handleRenewalSubmitted,
    };
  },
};
</script>

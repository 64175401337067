<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="form-group">
          <label for="email" class="form-label font-light">Plate Number</label>
          <input
            type="text"
            id="email"
            class="form-input"
            placeholder="Enter Plate Number"
            required
            v-model="formData.plateNumber"
          />
        </div>
        <div class="form-group">
          <label for="email" class="form-label font-light">Email</label>
          <input
            type="email"
            id="email"
            class="form-input"
            placeholder="Enter Email"
            required
            v-model="formData.email"
          />
        </div>
      </div>

      <div class="text-right">
        <button
          type="submit"
          class="btn px-6 rounded-md text-snow-white bg-cerise-red hover:bg-red-700 text-xs mb-4 h-12 uppercase disabled:bg-gray-300"
          :disabled="state.loading"
        >
          {{ state.loading ? "Please wait..." : "Submit" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { onMounted, reactive } from 'vue';
import { postData } from '../../helpers/axios';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const route = useRoute();

    const state = reactive({
      loading: false,
    });

    const formData = reactive({
      plateNumber: '',
      email: ''
    });

    onMounted(() => {
      formData.plateNumber = route.query.np || '';
    })

    const handleSubmit = async () => {
      state.loading = true;
      const { error, data } = await postData('api/initiate-payment', formData);
      state.loading = false;

      if (error) {
        const message = error.response?.data?.message || 'An error occured';
        console.log('ffs', error.response);
        Swal.fire('Error', message, 'error');
        return;
      }

      const proceed = await Swal.fire({
        title: 'Continue?',
        text: 'All data diven seem to be correct. Would you like to proceed to payment?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Proceed'
      });

      if (!proceed)
        return;

      location.href = data.paymentUrl;
    };

    return {
      state,
      formData,
      handleSubmit
    };
  },
}
</script>

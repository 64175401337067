<template>
  <Transition name="fade">
    <div
      v-if="showing"
      class="fixed inset-0 z-10 overflow-y-auto"
      @click.self="close"
    >
      <div class="flex items-center justify-center min-h-screen m-8">
        <div class="fixed inset-0 bg-black opacity-30"></div>
        <div :class="`relative mx-auto bg-white rounded p-8 ${props.width}`">
          <div class="flex mb-4">
            <div class="grow">
              <h4 class="text-2xl text-black">{{ props.title }}</h4>
            </div>
            <button
              aria-label="close"
              class="flex-none absolute top-0 right-0 text-2xl text-gray-500 my-2 mx-4"
              @click.prevent="close"
            >
              &times;
            </button>
          </div>
          <div class="pt-4">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { watch } from "vue";
export default {
  props: {
    showing: { required: true, type: Boolean },
    title: { required: false, type: String },
    width: { required: false, type: String, default: "max-w-xl" },
  },
  setup(props, { emit }) {
    const close = () => emit("close");

    watch(
      () => props.showing,
      (value) => {
        if (value) {
          return document
            .querySelector("body")
            .classList.add("overflow-hidden");
        }

        document.querySelector("body").classList.remove("overflow-hidden");
      },
    );

    return {
      props,
      close,
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <div class="px-4 lg:px-0 pt-40">
    <h1
      class="max-w-2xl text-3xl lg:text-3xl font-bold capitalize text-white mb-2 text-center mx-auto"
    >
      {{ props.title }}
    </h1>
    <p class="md:text-sm sm:text-xs text-white text-center">
      {{ props.subtitle }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String },
    subtitle: { type: String },
  },
  setup(props) {
    return {
      props,
    };
  },
};
</script>

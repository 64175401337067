<template>
  <div class="px-4 md:px-9 lg:px-28 py-8 lg:pt-16">
    <div
      class="flex justify-between mb-9 px-4 py-4 rounded-md lg:mb-8"
      style="background-color: #f3f5f4"
    >
      <h3 class="text-left text-sm font-normal">
        <span> Vehicle and Fine Details of Plate Number: </span>
        <span class="font-bold">&nbsp;{{ state.info.numberPlate }}</span>
      </h3>
      <div class="flex items-center space-x-3">
        <label for="toggle" class="text-xs text-gray-700"
          >Renewal Reminder.</label
        >
        <div
          class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
        >
          <input
            type="checkbox"
            name="toggle"
            id="toggle"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            @change="(e) => setReminder(e)"
          />
          <label
            for="toggle"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          ></label>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 min-w-max">
      <div class="border border-gray-200 rounded-lg">
        <h4
          class="font-normal mb-4 text-center py-4 rounded-lg"
          style="background-color: #edcece"
        >
          Vehicle Status
        </h4>
        <div style="max-height: 240px; min-height: 50px">
          <div
            class="grid grid-cols-2 px-4 py-2"
            v-for="item in visibleAgencies"
            :key="item.label"
          >
            <p class="text-sm text-app-gray mb-6">
              {{ item.label }}
            </p>
            <p class="text-sm text-right">
              <button
                class="font-semibold underline mr-4"
                @click="renew(item.label)"
              >
                Renew
              </button>
              <span
                :class="`${
                  responseStatus(item.isFound, item.isValid).style
                } px-2 text-sm py-1 rounded-full`"
              >
                {{ responseStatus(item.isFound, item.isValid).text }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <!-- <hr /> -->

      <div class="mt-4 md:mt-0 border border-gray-200 rounded-lg">
        <h4
          class="font-normal mb-4 text-center py-4 rounded-lg"
          style="background-color: #edcece"
        >
          Bill(s)
        </h4>
        <div
          style="max-height: 240px; min-height: 150px; overflow-y: scroll"
          class="flex items-center justify-center"
        >
          <div v-if="!state.info.billCount">
            <img
              src="/images/bill.svg"
              alt="Bill image"
              class="mx-auto w-10 mb-2"
            />
            <p class="text-sm">You do not have any unpaid bill(s)</p>
          </div>
          <div v-else>
            <img
              src="/images/bill.svg"
              alt="Bill image"
              class="mx-auto w-10 mb-2"
            />
            <p class="text-sm">
              You have
              <span class="font-bold">{{ state.info.billCount }}</span> unpaid
              bill(s).
              <a
                :href="`${state.payUrl}?numberPlate=${state.info.numberPlate}`"
                target="_blank"
                class="underline"
                >Click here to pay your bills</a
              >
            </p>
          </div>
        </div>
      </div>

      <!-- <div class="lg:col-span-5">
        <div class="bg-blue-100 h-full bg-cover bg-right lg:bg-left pl-20 lg:pl-64 pt-4 lg:pt-20 text-snow-white"
          style="background-image: url('/images/renewal_banner.png')">
          <h3 class="font-bold text-lg lg:text-3xl">Renewal Reminder</h3>
          <p class="lg:text-lg mb-2">
            Click
            <a href="#" class="uppercase text-sky-blue font-semibold" @click="setReminder()">here</a>
            request for renewal reminder
          </p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive } from "vue";
// import { event } from "vue-gtag";
import { useRoute } from "vue-router";

export default {
  props: {
    data: { type: Object, default: () => ({}) },
  },

  setup(props, { emit }) {
    const route = useRoute();

    const state = reactive({
      info: {
        agencyList: [],
      },
      payUrl: process.env.VUE_APP_PAY_URL,
    });

    const visibleAgencies = computed(() => {
      return route.query.showAll
        ? state.info.agencyList
        : state.info.agencyList.filter(
            (item) => item.label !== "Vehicle Registration",
          );
    });

    onMounted(() => {
      state.info = props.data;
      // screenview({
      //   app_name: 'Platedetect',
      //   screen_name: 'Plate Search Result',
      // })
    });

    const responseStatus = (isFound, isValid) => {
      if (!isFound) {
        return { text: "Not Found", style: "text-chip-text-red bg-chip-red" };
      } else if (isValid) {
        return { text: "Valid", style: "text-chip-text-green bg-chip-green" };
      } else {
        return { text: "Invalid", style: "text-cerise-red bg-we-peep" };
      }
    };

    const setReminder = (e) => {
      console.log(e.target.value);
      // event("reminder_request", {
      //   event_category: "form_open",
      //   event_label: "Reminder request",
      //   count: 1,
      // });
      emit("set-reminder");
    };

    const gotToPayment = () => {
      // event("go_to_payment_page", {
      //   event_category: "link_click",
      //   event_label: "Go to payment page",
      //   count: 1,
      // });
    };

    const renew = (label) => {
      if (label === "Insurance") {
        emit("invokeAction", {
          type: "path",
          value: "buy-insurance",
        });
        return;
      }

      if (label === "Vehicle Registration") {
        emit("invokeAction", {
          type: "link",
          value: "https://vreg.autoreglive.com/",
        });
        return;
      }

      alert("Coming soon");
    };

    return {
      state,
      responseStatus,
      setReminder,
      gotToPayment,
      renew,
      visibleAgencies,
    };
  },
};
</script>

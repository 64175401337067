<template>
  <div class="flex flex-col h-screen w-screen justify-between">
    <TheHeader />

    <main class="w-full mx-auto mb-auto flex-grow overflow-y-scroll">
      <router-view />
    </main>

    <!-- <TheFooter /> -->
  </div>
</template>

<script>
import { TheHeader } from "@/components/shared";

export default {
  components: {
    TheHeader,
    // TheFooter,
  },
  setup() {},
};
</script>

<template>
  <div class="px-4 sm:px-2 lg:px-0 mt-20">
    <div class="grid grid-cols-1 md:grid-cols-3 justify-items-stretch gap-4 lg:mx-20 mx-7">
      <div class="h-52 w-full rounded-2xl relative bg-cover mb-4 bg-white" v-for="item in items" :key="item.button">
        <img :src="`images/${item.image}`" class="absolute -bottom-2 left-3 md:left-1" />
        <span p class="absolute top-4 left-4 text-black font-semibold">
          {{ item.text }}
        </span>
        <button :class="btnClass(item.isReady)" :disabled="!item.isReady"
          @click.prevent="emit('invokeAction', item.action)">
          {{ item.isReady ? item.buttonText : 'Coming Soon' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  setup(props, { emit }) {
    const items = reactive([
      {
        text: "Vehicle Insurance",
        image: "v-insurance.svg",
        buttonText: "Coming Soon",
        isReady: true,
        action: {
          type: 'link',
          value: '#'
        }
      },
      {
        text: "Vehicle Particulars",
        image: "vp-insurance.svg",
        buttonText: "Renew Particulars",
        isReady: true,
        action: {
          type: "link",
          value: "https://vreg.autoreglive.com/",
        },
      },
      {
        text: "Road Worthiness Renewal",
        image: "rw-insurance.svg",
        buttonText: "Renew Road Worthiness",
        isReady: false,
        action: {
          type: "link",
          value: "#",
        },
      },
    ]);

    const btnClass = isReady => `absolute bottom-3 right-3 text-base font-semibold rounded px-4 py-3 ${isReady ? "cursor-pointer text-white hover:text-gray-100 bg-dark-gray" : "cursor-not-allowed text-cerise-red bg-chip-red rounded-full"
      }`;

    return {
      items,
      emit,
      btnClass,
    };
  },
};
</script>

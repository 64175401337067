<template>
  <div class="bg-white rounded-md mt-8 md:mt-12 mx-auto max-w-2xl px-4 pt-6 pb-2">
    <InitiateForm />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import InitiateForm from "../buy-insurance/InitiateForm";


export default {
  components: {
    InitiateForm
  },
  setup(props, { emit }) {
    const state = reactive({
      loading: false
    });
    const store = useStore();
    const handleSuccess = () => {
      Swal.fire("Success", "Your purchase wss successful", "success");
      emit("success");
    };

    return {
      state,
      emit,
      selectedInsuranceType:  computed(() => store.state.selectedInsuranceType),
      handleSuccess,
    };
  },
};
</script>

<template>
  <div class="h-full">
    <div
      style="
        background: url('/images/bg-image.svg');
        background-size: cover;
        height: 100%;
      "
      class="overflow-y-scroll"
    >
      <div class="container mx-auto">
        <TitleBar
          title="Payment Confirmation"
          subtitle=""
        />

        <div class="bg-white rounded-md mt-8 md:mt-12 mx-auto max-w-2xl px-4 pt-6 pb-2 mb-8">
          <div v-if="state.currentStatus == 'payment' && !state.loading">
              <p v-if="state.error">
                Unfortunately, we could not confirm the payment at this time. Kindly contact us for assistance.
              </p>
              <div v-else>
                <h4 class="text-lg font-semibold mb-2">
                  Thank you for your payment.
                </h4>
              </div>
          </div>

          <div v-if="state.currentStatus == 'insurance' && !state.loading">
            <p v-if="state.error">
              Unfortunately, we could not finalize the purchase. Kindly contact us for assistance.
              <br>
              <strong>Reason: </strong> {{ state.errorMessage }}
            </p>

            <div v-else>
              <p class="mb-2">
                You have successfully purchased the insurance policy for the following vehicle: <strong>{{ state.paymentData.plateNumber }}</strong>
              </p>

              <p class="text-sm mb-4">
                Kindly keep the details below for future reference.
              </p>

              <p class="text-md mb-6">
                <strong>Amount: </strong> {{ numberFormat(state.paymentData.amount / 100) }} <br>
                <strong>Reference Number: </strong> {{ state.paymentData.reference }} <br>
                <strong>Date: </strong> {{ dateFormat(state.paymentData.paidAt) }} <br>
                <strong>Payment For: </strong> {{ state.paymentData.paidFor }} <br>
                <strong>Policy Number: </strong> {{ state.insuranceData.data.meta.policy_number }} <br>
              </p>

              <div class="mb-2">
                <button
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                  @click="printReceipt"
                >
                  Print
                </button>
              </div>

              <p class="mt-6" v-if="state.insuranceData.data.meta.certificate_url">
                You can download your certificate from the link below. <br>
                <a :href="state.insuranceData.data.meta.certificate_url" target="_blank" class="underline text-blue-500">Download Certificate</a>
              </p>
            </div>
          </div>

          <div class="flex flex-col justify-center" v-if="state.loading">
            <img :src="spinnerIcon" alt="" class="h-24" />
            <p class="text-center">
              {{ loadingMessage }}
            </p>
          </div>

          <p class="text-center mt-4">
            <router-link to="/" class="underline text-butterfly-bush">Go Home</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TitleBar } from "@/components/home";
import { computed, onMounted, reactive } from "vue";
import { useRoute } from 'vue-router';
import { getData, postData } from '../helpers/axios';
import { numberFormat, dateFormat } from '../helpers';

export default {
  components: {
    TitleBar,
  },
  setup() {
    const route = useRoute();

    const spinnerIcon = require('../assets/icons/spinner.svg');

    const state = reactive({
      currentStatus: 'payment',
      loading: false,
      error: false,
      errorMessage: '',
      paymentData: {
        extraData: ""
      },
      insuranceData: {}
    });

    const loadingMessage = computed(() => {
      return state.currentStatus == 'payment'
        ? 'Payment confirmation in progress. Please wait...'
        : 'Please wait while we finalize the insurance purchase.';
    });

    onMounted(() => {
      fetchPaymentDetails(route.query.reference);
    });

    const fetchPaymentDetails = async (reference) => {
      state.currentStatus = 'payment';
      state.loading = true;
      const { error, data } = await getData(`api/confirm-payment/${reference}`);
      state.loading = false;

      if (error || !data) {
        state.error = true;
        return;
      }

      state.paymentData = data;

      await buyInsurance();
    };

    const buyInsurance = async () => {
      state.currentStatus = 'insurance';
      state.loading = true;
      const payload = JSON.parse(state.paymentData.extraData);
      payload.registration_number = payload.number_plate;
      payload.year_of_manufacture = payload.year_of_manufacture.toString();

      const { error, data } = await postData('api/buy-insurance', payload);
      state.loading = false;

      if (error || !data) {
        state.error = true;
        state.errorMessage = 'An unknown error occured';
        return;
      }

      if (data.responseCode !== 1) {
        state.error = true;
        state.errorMessage = data.responseText;
        return;
      }

      state.error = false;
      state.insuranceData = data;
      state.errorMessage = error;
    };

    const printReceipt = () => {
      window.print();
    };

    return {
      state,
      numberFormat,
      dateFormat,
      spinnerIcon,
      loadingMessage,
      printReceipt,
    };
  },
};
</script>

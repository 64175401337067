<template>
  <!-- <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 pb-8 px-4 sm:px-2 lg:px-0"> -->
  <!-- <div class="grid grid-cols-1 pb-8 px-4 sm:px-2 lg:px-0"> -->
  <!-- <div class="lg:col-span-1 pt-8 lg:pt-16"> -->
  <!-- <div class="border border-gray-200 p-4"> -->
  <div class="px-14">
    <div class="bg-white rounded-xl md:rounded-tl-none mt-8 md:mt-12 mx-auto max-w-3xl p-2">
      <form @submit.prevent="handleSubmit">
        <div class="grid grid-cols-1 md:grid-cols-5">
          <select name=""
            class="w-full px-2 text-black text-xs md:text-sm border-collapse bg-transparent w-28 md:w-36 h-12 outline-none md:border-r"
            v-model="formData.selectedState" required>
            <!-- <option value="">Select state</option> -->
            <option v-for="item in states" 
            :key="item.value"
            :value="item.value">
              {{ item.label }}
            </option>
          </select>

          <input type="text"
            class="w-full md:col-span-3 px-4 text-black text-sm w-full bg-transparent rounded-md border-collapse h-12 outline-none mb-2 md:mb-0"
            placeholder="Search by plate number" v-model="formData.numberPlate" required />

          <button type="submit" :class="`w-full btn px-2 rounded-md text-white text-md font-semibold w-32 h-12 ${loading
            ? 'cursor-not-allowed bg-chip-red'
            : 'bg-cerise-red hover:bg-red-700 '
          }`" :disabled="loading">
            {{ loading? "Please wait...": "Search" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";

export default {
  setup(props, { emit }) {
    const route = useRoute();

    const loading = ref(false);

    const states = reactive([
      { label: "Lagos State", value: "LAG" },
      { label: "Nassarawa State", value: "NAS" },
    ]);

    const formData = reactive({
      numberPlate: "",
      selectedState: "LAG",
    });

    onMounted(() => {
      formData.numberPlate = route.query.np || ''
    });

    const handleSubmit = async () => {
      emit("fetching");
      loading.value = true;

      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}api/search?numberPlate=${formData.numberPlate}&instance=${formData.selectedState}`,
        );

        emit("resultFound", data.data);
      } catch (error) {
        console.log(error);
        alert("An error occurred");
      }
      loading.value = false;
    };

    return {
      loading,
      states,
      formData,
      handleSubmit,
    };
  },
};
</script>

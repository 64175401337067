<template>
  <div class="h-full">
    <!-- <div v-show="!state.showResult" -->
    <div
      v-show="!state.showResult"
      style="
        background: url('/images/bg-image.svg');
        background-size: cover;
        height: 100%;
      "
      class="overflow-y-scroll"
    >
      <div class="container mx-auto">
        <TitleBar
          title="Check the status of Vehicle and Fine Details "
          subtitle="Plate detect makes it possible to check and renew vechicle documents"
        />

        <MainSection
          @fetching="state.showResult = false"
          @result-found="handleResult"
        />

        <ComingSoon @invoke-action="handlePurchaseAction" />
      </div>
    </div>

    <!-- <div v-if="state.showResult" -->
    <section v-if="state.showResult">
      <div class="text-left ml-4 md:ml-9 lg:ml-28 mt-5 font-semibold">
        <button href="#" @click="state.showResult = false" class="">
          <font-awesome-icon :icon="backIcon" /> &nbsp; Back
        </button>
      </div>
      <StatusResult
        :data="state.resultData"
        @setReminder="state.showModal = true"
        @invoke-action="handlePurchaseAction"
      />
    </section>

    <card-modal
      :showing="state.showModal"
      @close="state.showModal = false"
      title="Set Renewal Reminder"
    >
      <RenewalReminderForm
        @cancel="state.showModal = false"
        @submit="handleRenewalSubmitted"
      />
    </card-modal>

    <card-modal
      title="Buy Insurance"
      :showing="state.currentModal == 'buy-insurance'"
      width="max-w-3xl"
      @close="handleCloseModal"
    >
      <BuyInsuranceForm @cancel="handleCloseModal" @success="state.currentModal = ''"/>
    </card-modal>

    <card-modal
      title="Buy Insurance"
      :showing="state.currentModal == 'pay-first-popup'"
      width="max-w-3xl"
      @close="handleCloseModal"
    >
      <PaymentForm @cancel="handleCloseModal" @success="state.currentModal = 'buy-insurance'"/>
    </card-modal>
  </div>
</template>

<script>
import {
BuyInsuranceForm,
PaymentForm,
RenewalReminderForm
} from "@/components/forms";
import {
ComingSoon,
MainSection,
StatusResult,
TitleBar
} from "@/components/home";
import { CardModal } from "@/components/shared";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';
import { computed, reactive } from "vue";
import { useRouter } from 'vue-router';

export default {
  components: {
    TitleBar,
    MainSection,
    ComingSoon,
    StatusResult,
    CardModal,
    RenewalReminderForm,
    BuyInsuranceForm,
    PaymentForm,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      resultData: {},
      showResult: false,
      showModal: false,
      currentModal: "",
      foo: false,
    });

    const handleResult = (data) => {
      state.resultData = data;
      state.showResult = true;
    };

    const backIcon = computed(() => faChevronLeft);

    const handleCloseModal = () => {
      state.currentModal = "";
    };

    const handlePurchaseAction = ({ type, value }) => {
      if (type === "path") {
        router.push(value);
        return;
      }

      if (type === "link") {
        window.open(value, "_blank");
        return;
      }

      state.currentModal = value;
    };

    const showMainSection = () => {
      // screenview({
      //   app_name: 'Platedetect',
      //   screen_name: 'Main Section',
      // });
      state.showResult = false;
    };

    const handleRenewalSubmitted = () => {
      state.showModal = false;
      Swal.fire(
        "Success",
        "Thank you. You will be sent a reminder before your renewal is due",
        "success",
      );
    };

    return {
      state,
      handleResult,
      backIcon,
      handleCloseModal,
      handlePurchaseAction,
      showMainSection,
      handleRenewalSubmitted,
    };
  },
};
</script>

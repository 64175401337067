<template>
  <div>
    <form @submit.prevent="getPayments">
      <p class="font-bold pb-4 text-sm">Already Paid?, continue to insurace renewal</p>
      <div class="grid sm:grid-cols-3 gap-x-4 gap-y-0">
        <div class="form-group">
          <label for="email" class="text-sm">Email Address</label>
          <input type="email" v-model="state.email" id="email" required class="form-input" />
        </div>
        <div class="form-group">
          <label for="reference" class="text-sm">Reference</label>
          <input type="text" v-model="state.reference" id="reference" required class="form-input" />
        </div>
        <button
          type="submit"
          class="btn mt-8 px-2 rounded-md text-snow-white bg-cerise-red hover:bg-red-700 text-xs mb-6 h-12 uppercase disabled:bg-gray-300"
          :disabled="state.loading"
        >
          {{ state.loadingRRR ? "Please wait..." : "Continue" }}
        </button>
      </div>
    </form>

    <hr class="pb-3" />

    <form id="paymentForm" @submit.prevent="handleSubmit">
      <p class="font-bold pb-4 text-sm">Make purchase</p>
      <div class="grid sm:grid-cols-1 gap-x-4 gap-y-0">
        <div class="form-group">
          <label for="email" class="text-sm">Email Address</label>
          <input
            type="email"
            v-model="state.data.email"
            id="email-address"
            required
            class="form-input"
          />
        </div>
        <div class="form-group">
          <label for="first-name" class="text-sm">First Name</label>
          <input
            type="text"
            v-model="state.data.firstname"
            id="first-name"
            class="form-input"
          />
        </div>
        <div class="form-group">
          <label for="last-name" class="text-sm">Last Name</label>
          <input
            type="text"
            v-model="state.data.lastname"
            id="last-name"
            class="form-input"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4">
        <button
          type="button"
          class="btn px-2 rounded-md text-cerise-red transparent hover:bg-red-700 hover:text-snow-white text-xs mb-4 h-12 uppercase border border-cerise-red"
          :disabled="state.loading"
          @click="$emit('on-cancel')"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="btn px-2 rounded-md text-snow-white bg-cerise-red hover:bg-red-700 text-xs mb-4 h-12 uppercase disabled:bg-gray-300"
          :disabled="state.loading"
        >
          {{ state.loading ? "Please wait..." : "Submit" }}
        </button>
      </div>
    </form>

    <paystack v-if="state.show" buttonClass="'button-class btn btn-primary'" buttonText="Pay Online"
      :amount="companies[selectedInsuranceType].price * 100" :firstname="state.data.firstname" :lastname="state.data.lastname"
      :email="state.data.email" :paystack-key="payStackKey"
      :reference="referenceText" :callback="processPayment" :close="close">
    </paystack>
  </div>
</template>

<script>
import axios from 'axios';
import paystack from 'vue-paystack2';

// import ErrorSummary from "../inc/ErrorSummary.vue";

export default {
  components: {
    // ErrorSummary,
    paystack
  },
  props: {
    index: { required: false, type: Number, default: 0 },
  },
  data() {
    return {
      state: {
        show: false,
        loading: false,
        loadingRRR: false,
        reference: "",
        email: "",
        data: {
          email: "",
          firstname: "",
          lastname: "",
        },
      },
    }
  },
  methods: {
    getPayments() {
    axios.post(`${process.env.VUE_APP_API_URL}payments/fetch`, {
        reference: this.state.reference,
        email: this.state.email,
        planId: this.companies[this.selectedInsuranceType]._id,
      })
      .then((e) => {
        this.$store.commit({
            type: "setOrderData",
            orderData: {
              email: this.state.email,
              planId: this.companies[this.selectedInsuranceType]._id,
              reference: this.state.reference,
             },
          });
        this.$emit("on-success");
        console.log(e);
      }).catch((e) => {
        console.log(e);
      });
    },
    processPayment() {
      window.alert("Payment recieved")
      axios.post(`${process.env.VUE_APP_API_URL}payments/save`, {
        email: this.state.data.email,
        planId: this.companies[this.selectedInsuranceType]._id,
        reference: this.reference,
      })
        .then(response => {
          console.log(response);
          this.state.show = false;
          this.$store.commit({
            type: "setOrderData",
            orderData: {
              email: this.state.data.email,
              planId: this.companies[this.selectedInsuranceType]._id,
              reference: this.referenceText,
             },
          });
          this.$emit("on-success")
        })
        .catch(error => {
          console.log(error);
          this.state.show = false;
        });
    },
    close() {
      console.log("You closed checkout page")
      this.state.show = false;
    },
    handleSubmit() {
      if (this.companies[this.selectedInsuranceType]) {
       this.state.show = !this.state.show;
      }
      console.log(this);
    },
  },
  computed: {
    companies() {
      return this.$store.state.insuranceCompanies;
    },
    selectedInsuranceType() {
      return this.$store.state.selectedInsuranceType;
    },
    referenceText() {
      let text = `${this.state.data.firstname}-${this.state.data.lastname}`;
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      console.log(text)
      return text;
    },
    payStackKey() {
      return process.env.VUE_APP_PAYSTACK_KEY;
    }
  },
}
</script>

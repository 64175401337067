import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import BuyInsurance from "../views/BuyInsurance.vue";
import ConfirmPayment from "../views/ConfirmPayment.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/buy-insurance",
    name: "BuyInsurance",
    component: BuyInsurance,
  },
  {
    path: "/confirm-payment",
    name: "ConfirmPayment",
    component: ConfirmPayment,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

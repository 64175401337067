import insuranceData from "../../../data/insurance";

let aiicoform = [
  {
    name: "Title",
    type: "select",
    value: "",
    span: 1,
    id: "title",
    valueTypes: insuranceData.titles,
    required: true,
  },
  {
    name: "First Name",
    type: "text",
    value: "",
    span: 1,
    id: "first_name",
    required: true,
  },
  {
    name: "Last Name",
    type: "text",
    value: "",
    span: 1,
    id: "last_name",
    required: true,
  },
  {
    name: "Date Of Birth",
    type: "date",
    value: "",
    span: 1,
    id: "date_of_birth",
    required: true,
  },
  {
    name: "Email",
    type: "email",
    value: "",
    span: 1,
    id: "email",
    required: true,
  },
  {
    name: "Phone Number",
    type: "text",
    value: "",
    span: 1,
    id: "phone_number",
    required: true,
  },
  {
    name: "Address",
    type: "text",
    value: "",
    span: 2,
    id: "address",
    required: true,
  },
  {
    name: "State",
    type: "select",
    value: "",
    span: 1,
    id: "state",
    valueTypes: insuranceData.states,
    required: true,
  },
  {
    name: "BVN",
    type: "text",
    value: "",
    span: 2,
    id: "bvn",
    required: true,
  },
  {
    name: "Plate Number",
    type: "text",
    value: "",
    span: 1,
    id: "registration_number",
    required: true,
  },
  {
    name: "Vehicle Category",
    type: "select",
    value: "",
    span: 1,
    id: "vehicle_category",
    valueTypes: insuranceData.vehicleCategories,
    required: true,
  },
  {
    name: "Year of Manufacture",
    type: "text",
    value: "",
    span: 1,
    id: "year_of_manufacture",
    required: true,
  },
  {
    name: "Vehicle Cost",
    type: "number",
    value: null,
    span: 1,
    id: "vehicle_cost",
    required: true,
  },
  {
    name: "ID Type",
    type: "select",
    value: "",
    span: 1,
    id: "vehicle_category",
    valueTypes: insuranceData.idTypes,
    required: true,
  },
  {
    name: "Upload ID",
    type: "upload",
    value: "",
    span: 2,
    id: "identification_url",
    required: true,
  },
];

let custodian = [
  {
    name: "First Name",
    type: "text",
    value: "",
    span: 1,
    id: "first_name",
    required: true,
  },
  {
    name: "Last Name",
    type: "text",
    value: "",
    span: 1,
    id: "last_name",
    required: true,
  },
  {
    name: "Date Of Birth",
    type: "date",
    value: "",
    span: 1,
    id: "dob",
    required: true,
  },
  {
    name: "Email",
    type: "email",
    value: "",
    span: 1,
    id: "email",
    required: true,
  },
  {
    name: "Gender",
    type: "select",
    value: "",
    span: 1,
    valueTypes: insuranceData.gender,

    id: "gender",
    required: true,
  },
  {
    name: "Phone Number",
    type: "text",
    value: "",
    span: 1,
    id: "phone",
    required: true,
  },
  {
    name: "Insurance Start Date",
    type: "date",
    value: "",
    span: 1,
    id: "insurance_start_date",
    required: true,
  },
  {
    name: "Address",
    type: "text",
    value: "",
    span: 2,
    id: "address",
    required: true,
  },
  {
    name: "Bought For Self",
    type: "boolean",
    value: false,
    span: 1,
    track: [
      {
        id: "first_name",
        to: "buyer_first_name",
      },
      {
        id: "last_name",
        to: "buyer_last_name",
      },
      {
        id: "email",
        to: "buyer_email",
      },
      {
        id: "phone",
        to: "buyer_phone",
      },
    ],
    id: "bought_for_self",
    required: true,
  },
  {
    name: "Buyer's First Name",
    type: "text",
    value: "",
    span: 1,
    id: "buyer_first_name",
    required: true,
  },
  {
    name: "Buyer's Last Name",
    type: "text",
    value: "",
    span: 1,
    id: "buyer_last_name",
    required: true,
  },
  {
    name: "Buyer's Email",
    type: "email",
    value: "",
    span: 1,
    id: "buyer_email",
    required: true,
  },
  {
    name: "Buyer's Phone Number",
    type: "text",
    value: "",
    span: 1,
    id: "buyer_phone",
    required: true,
  },
  {
    name: "Buyer's Gender",
    type: "select",
    value: "",
    span: 1,
    valueTypes: insuranceData.gender,
    id: "buyer_gender",
    required: true,
  },
  {
    name: "Vehicle Insurance Type",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_insurance_type",
    required: true,
  },
  {
    name: "vehicle Make",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_make",
    required: true,
  },
  {
    name: "Vehicle Model",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_make",
    required: true,
  },
  {
    name: "Vehicle Registration Number",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_registration_number",
    required: true,
  },
  {
    name: "Vehicle Year Manufactured",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_registration_number",
    required: true,
  },
  {
    name: "Vehicle Type",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_type",
    valueTypes: insuranceData.vehicleCategories,
    required: true,
  },
  {
    name: "Vehicle Value",
    type: "number",
    value: "",
    span: 1,
    id: "vehicle_value",
    required: true,
  },
  {
    name: "Vehicle Color",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_color",
    required: true,
  },
  {
    name: "Vehicle Registration Number",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_type",
    required: true,
  },
  {
    name: "Engine Number",
    type: "text",
    value: "",
    span: 1,
    id: "engine_number",
    required: true,
  },
  {
    name: "Chassis Number",
    type: "text",
    value: "",
    span: 1,
    id: "chassis_number",
    required: true,
  },
  {
    name: "Plate Number",
    type: "text",
    value: "",
    span: 1,
    id: "registration_number",
    required: true,
  },
];

let leadway = [
  {
    name: "First Name",
    type: "text",
    value: "",
    span: 1,
    id: "first_name",
    required: true,
  },
  {
    name: "Last Name",
    type: "text",
    value: "",
    span: 1,
    id: "last_name",
    required: true,
  },
  {
    name: "Date Of Birth",
    type: "date",
    value: "",
    span: 1,
    id: "dob",
    required: true,
  },
  {
    name: "City",
    type: "text",
    value: "",
    span: 1,
    id: "city",
    required: true,
  },
  {
    name: "State",
    type: "select",
    value: "",
    span: 1,
    id: "state",
    valueTypes: insuranceData.states,
    required: true,
  },
  {
    name: "Email",
    type: "email",
    value: "",
    span: 1,
    id: "email",
    required: true,
  },
  {
    name: "Gender",
    type: "select",
    value: "",
    span: 1,
    valueTypes: insuranceData.gender,
    id: "gender",
    required: true,
  },
  {
    name: "Marital Status",
    type: "select",
    value: "",
    span: 1,
    id: "marital_status",
    valueTypes: insuranceData.maritalStatus,
    required: true,
  },
  {
    name: "Phone Number",
    type: "text",
    value: "",
    span: 1,
    id: "phone",
    required: true,
  },
  {
    name: "Insurance Start Date",
    type: "date",
    value: "",
    span: 1,
    id: "insurance_start_date",
    required: true,
  },
  {
    name: "City",
    type: "text",
    value: "",
    span: 1,
    id: "city",
    required: true,
  },
  {
    name: "State",
    type: "select",
    value: "",
    span: 1,
    id: "state",
    valueTypes: insuranceData.states,
    required: true,
  },
  {
    name: "Address",
    type: "text",
    value: "",
    span: 2,
    id: "address",
    required: true,
  },
  {
    name: "Bought For Self",
    type: "boolean",
    value: false,
    span: 1,
    track: [],
    id: "bought_for_self",
    required: true,
  },
  {
    name: "Vehicle Insurance Type",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_insurance_type",
    required: true,
  },
  {
    name: "vehicle Make",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_make",
    required: true,
  },
  {
    name: "Vehicle Model",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_make",
    required: true,
  },
  {
    name: "Vehicle Registration Number",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_registration_number",
    required: true,
  },
  {
    name: "Vehicle Year Manufactured",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_registration_number",
    required: true,
  },
  {
    name: "Vehicle Type",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_type",
    valueTypes: insuranceData.vehicleCategories,
    required: true,
  },
  {
    name: "Vehicle Value",
    type: "number",
    value: "",
    span: 1,
    id: "vehicle_value",
    required: true,
  },
  {
    name: "Vehicle Color",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_color",
    required: true,
  },
  {
    name: "Vehicle Registration Number",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_type",
    required: true,
  },
  {
    name: "Engine Number",
    type: "text",
    value: "",
    span: 1,
    id: "engine_number",
    required: true,
  },
  {
    name: "Chassis Number",
    type: "text",
    value: "",
    span: 1,
    id: "chassis_number",
    required: true,
  },
  {
    name: "Plate Number",
    type: "text",
    value: "",
    span: 1,
    id: "registration_number",
    required: true,
  },
];

let myCovergenius = [
  {
    name: "Title",
    type: "select",
    value: "",
    span: 1,
    id: "title",
    valueTypes: insuranceData.titles,
    required: true,
  },
  {
    name: "First Name",
    type: "text",
    value: "",
    span: 1,
    id: "first_name",
    required: true,
  },
  {
    name: "Last Name",
    type: "text",
    value: "",
    span: 1,
    id: "last_name",
    required: true,
  },
  {
    name: "Other Names",
    type: "text",
    value: "",
    span: 1,
    id: "other_names",
    required: true,
  },
  {
    name: "Date Of Birth",
    type: "date",
    value: "",
    span: 1,
    id: "dob",
    required: true,
  },
  {
    name: "City",
    type: "text",
    value: "",
    span: 1,
    id: "city",
    required: true,
  },
  {
    name: "State",
    type: "select",
    value: "",
    span: 1,
    id: "state",
    valueTypes: insuranceData.states,
    required: true,
  },
  {
    name: "Email",
    type: "email",
    value: "",
    span: 1,
    id: "email",
    required: true,
  },
  {
    name: "Occupation",
    type: "text",
    value: "",
    span: 1,
    id: "city",
    required: true,
  },
  {
    name: "Gender",
    type: "select",
    value: "",
    span: 1,
    id: "gender",
    valueTypes: insuranceData.gender,
    required: true,
  },
  {
    name: "Marital Status",
    type: "select",
    value: "",
    span: 1,
    id: "marital_status",
    valueTypes: insuranceData.maritalStatus,
    required: true,
  },
  {
    name: "Phone Number",
    type: "text",
    value: "",
    span: 1,
    id: "phone",
    required: true,
  },
  {
    name: "Next Of Kin Name",
    type: "text",
    value: "",
    span: 1,
    id: "next_of_kin_name",
    required: true,
  },
  {
    name: "Next Of Kin Phone",
    type: "text",
    value: "",
    span: 1,
    id: "next_of_kin_phone",
    required: true,
  },
  {
    name: "Next Of Kin Address",
    type: "text",
    value: "",
    span: 1,
    id: "next_of_kin_address",
    required: true,
  },
  {
    name: "Insurance Start Date",
    type: "date",
    value: "",
    span: 1,
    id: "insurance_start_date",
    required: true,
  },
  {
    name: "City",
    type: "text",
    value: "",
    span: 1,
    id: "city",
    required: true,
  },
  {
    name: "State",
    type: "select",
    value: "",
    span: 1,
    id: "state",
    valueTypes: insuranceData.states,
    required: true,
  },
  {
    name: "Address",
    type: "text",
    value: "",
    span: 2,
    id: "address",
    required: true,
  },
  {
    name: "Bought For Self",
    type: "boolean",
    value: false,
    span: 1,
    track: [],
    id: "bought_for_self",
    required: true,
  },
  {
    name: "Vehicle Insurance Type",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_insurance_type",
    required: true,
  },
  {
    name: "vehicle Make",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_make",
    required: true,
  },
  {
    name: "Vehicle Model",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_make",
    required: true,
  },
  {
    name: "Vehicle Registration Number",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_registration_number",
    required: true,
  },
  {
    name: "Vehicle Year Manufactured",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_registration_number",
    required: true,
  },
  {
    name: "Vehicle Type",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_type",
    valueTypes: insuranceData.vehicleCategories,
    required: true,
  },
  {
    name: "Vehicle Value",
    type: "number",
    value: "",
    span: 1,
    id: "vehicle_value",
    required: true,
  },
  {
    name: "Vehicle Color",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_color",
    required: true,
  },
  {
    name: "Vehicle Registration Number",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_type",
    required: true,
  },
  {
    name: "Engine Number",
    type: "text",
    value: "",
    span: 1,
    id: "engine_number",
    required: true,
  },
  {
    name: "Chassis Number",
    type: "text",
    value: "",
    span: 1,
    id: "chassis_number",
    required: true,
  },
  {
    name: "Plate Number",
    type: "text",
    value: "",
    span: 1,
    id: "registration_number",
    required: true,
  },
  {
    name: "Upload ID",
    type: "upload",
    value: "",
    span: 2,
    id: "identification_url",
    required: true,
  },
];

let sovereignTrust = [
  {
    name: "First Name",
    type: "text",
    value: "",
    span: 1,
    id: "first_name",
    required: true,
  },
  {
    name: "Last Name",
    type: "text",
    value: "",
    span: 1,
    id: "last_name",
    required: true,
  },
  {
    name: "Date Of Birth",
    type: "date",
    value: "",
    span: 1,
    id: "dob",
    required: true,
  },
  {
    name: "Email",
    type: "email",
    value: "",
    span: 1,
    id: "email",
    required: true,
  },
  {
    name: "Phone Number",
    type: "text",
    value: "",
    span: 1,
    id: "phone",
    required: true,
  },
  {
    name: "Insurance Start Date",
    type: "date",
    value: "",
    span: 1,
    id: "insurance_start_date",
    required: true,
  },

  {
    name: "Address",
    type: "text",
    value: "",
    span: 2,
    id: "address",
    required: true,
  },
  {
    name: "Plate Number",
    type: "text",
    value: "",
    span: 1,
    id: "registration_number",
    required: true,
  },
  {
    name: "Bought For Self",
    type: "boolean",
    value: false,
    span: 1,
    track: [
      {
        id: "first_name",
        to: "buyer_first_name",
      },
      {
        id: "last_name",
        to: "buyer_last_name",
      },
      {
        id: "email",
        to: "buyer_email",
      },
      {
        id: "phone",
        to: "buyer_phone",
      },
    ],
    id: "bought_for_self",
    required: true,
  },
  {
    name: "Buyer's First Name",
    type: "text",
    value: "",
    span: 1,
    id: "buyer_first_name",
    required: true,
  },
  {
    name: "Buyer's Last Name",
    type: "text",
    value: "",
    span: 1,
    id: "buyer_last_name",
    required: true,
  },
  {
    name: "Buyer's Email",
    type: "email",
    value: "",
    span: 1,
    id: "buyer_email",
    required: true,
  },
  {
    name: "Buyer's Phone Number",
    type: "text",
    value: "",
    span: 1,
    id: "buyer_phone",
    required: true,
  },
  {
    name: "Buyer's Gender",
    type: "select",
    value: "",
    span: 1,
    valueTypes: insuranceData.gender,
    id: "buyer_gender",
    required: true,
  },
  {
    name: "vehicle Make",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_make",
    required: true,
  },
  {
    name: "Vehicle Model",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_make",
    required: true,
  },
  {
    name: "Vehicle Insurance Type",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_insurance_type",
    required: true,
  },
  {
    name: "Vehicle Registration Number",
    type: "text",
    value: "",
    span: 1,
    id: "vehicle_registration_number",
    required: true,
  },
  {
    name: "Engine Number",
    type: "text",
    value: "",
    span: 1,
    id: "engine_number",
    required: true,
  },
  {
    name: "Chassis Number",
    type: "text",
    value: "",
    span: 1,
    id: "chassis_number",
    required: true,
  },
];

function parseForm(form) {
  let data = {};
  console.log(form);

  for (let e of form) {
    data[e.id] = e.value;
  }

  return data;
}

export { parseForm };

export default [aiicoform, leadway, custodian, myCovergenius, sovereignTrust];

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Dropzone from 'dropzone-vue';

import 'dropzone-vue/dist/drop-zone.common.css';
// import VueGtag from "vue-gtag";

createApp(App)
  .use(store)
  .use(router)
  .use(Dropzone)
  // .use(
  //   VueGtag,
  //   {
  //     appName: "Platedetect",
  //     pageTrackerScreenviewEnabled: true,
  //     config: { id: "G-9F9YR5SK9W" },
  //   },
  //   router,
  // )
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");

import { createStore } from "vuex";

export default createStore({
  state() {
    return {
      insuranceCompanies: [],
      orderData: {},
      selectedInsuranceType: null,
      currentInsurancePayload: {},
    };
  },
  mutations: {
    setInsuranceCompanies(state, payload) {
      state.insuranceCompanies = payload.insuranceCompanies;
    },
    setSelectedInsuranceType(state, payload) {
      state.selectedInsuranceType = payload.index;
    },
    setOrderData(state, payload) {
      state.orderData = payload.orderData;
    },
    setCurrentInsurancePayload(state, payload) {
      console.log('88888', payload);
      state.currentInsurancePayload = payload;
    }
  },
  actions: {},
  modules: {},
});

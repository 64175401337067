<template>
  <button
    class="flex items-center justify-center py-3 text-xs lg:text-sm font-semibold rounded-md px-2"
    :class="[
      props.selected
        ? 'text-white bg-cerise-red'
        : 'text-gray-500 bg-white border border-gray-500',
    ]"
    @click="onSelected"
  >
    {{ props.title }}
  </button>
</template>

<script>
export default {
  props: {
    selected: { required: true, type: Boolean },
    title: { required: true, type: String },
  },
  setup(props, { emit }) {
    const onSelected = () => {
      emit("on-selected");
    };

    return {
      props,
      onSelected,
    };
  },
};
</script>

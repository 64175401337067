<template>
  <div class="bg-white rounded-md mt-8 md:mt-12 mx-auto max-w-2xl mb-8 p-8 pb-6">
    <h3 class="text-lg text-cerise-red font-semibold mb-6">
      Fill in your details to begin your insurance purchase
    </h3>

    <form @submit.prevent="handleSubmit">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div class="form-group">
          <label for="title" class="form-label font-light">Title</label>
          <select
            id="title"
            class="form-input"
            required
            v-model="formData.title"
          >
            <option :value="''">Select Title</option>
            <option :value="item" v-for="item in insuranceData.titles" :key="item">
              {{ item }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="first_name" class="form-label font-light">First Name</label>
          <input
            type="text"
            id="first_name"
            class="form-input"
            placeholder="Your First Name"
            required
            v-model="formData.first_name"
          />
        </div>
        <div class="form-group">
          <label for="last_name" class="form-label font-light">Last Name</label>
          <input
            type="text"
            id="last_name"
            class="form-input"
            placeholder="Your Last Name"
            required
            v-model="formData.last_name"
          />
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div class="form-group">
          <label for="phone_number" class="form-label font-light"
            >Email Address</label
          >
          <input
            type="text"
            id="email"
            class="form-input"
            placeholder="Email Address"
            required
            v-model="formData.email"
          />
        </div>
        <div class="form-group">
          <label for="phone_number" class="form-label font-light"
            >Phone Number</label
          >
          <input
            type="text"
            id="phone_number"
            class="form-input"
            placeholder="Phone Number"
            required
            v-model="formData.phone_number"
          />
        </div>
        <div class="form-group">
          <label for="state" class="form-label font-light">State</label>
          <select
            id="state"
            class="form-input"
            required
            v-model="formData.state"
          >
            <option value="">Select State</option>
            <option :value="item" v-for="item in insuranceData.states" :key="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div class="form-group">
          <label for="number_plate" class="form-label font-light">Plate Number</label>
          <input
            type="text"
            id="number_plate"
            class="form-input"
            placeholder="Your plate number"
            required
            v-model="formData.number_plate"
          />
        </div>
        <div class="form-group md:col-span-2">
          <label for="address" class="form-label font-light">Address</label>
          <input
            type="text"
            id="address"
            class="form-input"
            placeholder="Your address"
            required
            v-model="formData.address"
          />
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div class="form-group">
          <label for="vehicle_category" class="form-label font-light">Vehicle Category</label>
          <select
            id="vehicle_category"
            class="form-input"
            required
            v-model="formData.vehicle_category"
          >
            <option value="">Select Category</option>
            <option :value="item" v-for="item in insuranceData.vehicleCategories" :key="item">
              {{ item }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="vehicle_cost" class="form-label font-light">Vehicle Cost</label
          >
          <input
            type="number"
            id="vehicle_cost"
            class="form-input"
            required
            min="0"
            v-model="formData.vehicle_cost"
          />
        </div>

        <div class="form-group">
          <label for="year_of_manufacture" class="form-label font-light">Year of Manufacture</label
          >
          <input
            type="number"
            id="year_of_manufacture"
            class="form-input"
            required
            v-model="formData.year_of_manufacture"
          />
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="form-group">
          <label for="bvn" class="form-label font-light">BVN</label>
          <input
            type="text"
            id="bvn"
            class="form-input"
            placeholder="Your BVN"
            required
            v-model="formData.bvn"
          />
        </div>
        <div class="form-group">
          <label for="dob" class="form-label font-light">Date of Birth</label>
          <input
            type="date"
            id="dob"
            class="form-input"
            placeholder="Select your D.O.B"
            required
            v-model="formData.date_of_birth"
          />
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="form-group">
          <label for="bvn" class="form-label font-light">Identification Type</label>
          <select
            id="identification_name"
            class="form-input"
            required
            v-model="formData.identification_name"
          >
            <option value="">Select ID Type</option>
            <option :value="item" v-for="item in insuranceData.idTypes" :key="item">
              {{ item }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="dob" class="form-label font-light">Upload ID</label>
          <input
            type="file"
            id="identification_file"
            class="form-input"
            placeholder="Upload ID"
            required
            @change="onFileChange"
          />
        </div>
      </div>

      <div class="text-right">
        <button
          type="submit"
          :class="['btn px-6 rounded-md text-snow-white text-xs mb-4 h-12 uppercase',
            state.loading ? 'bg-red-200 cursor-not-allowed' : 'bg-cerise-red hover:bg-red-700']"
          :disabled="state.loading"
        >
          {{ state.loading ? "Please wait..." : "Continue" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { reactive } from 'vue';
import insuranceData from '../../data/insurance';
import { postData } from '../../helpers/axios';
import Swal from 'sweetalert2';

export default {
  components: {
  },
  setup() {
    const state = reactive({
      loading: false,
      uploading: false,
      apiUrl: process.env.VUE_APP_API_URL
    });

    const formData = reactive(
      {
        number_plate: '',
        email: '',
        first_name: '',
        last_name: '',
        title: '',
        phone_number: '',
        state: '',
        vehicle_category: '',
        vehicle_cost: '',
        year_of_manufacture: '',
        address: '',
        bvn: '',
        date_of_birth: '',
        identification_name: '',
        identification_url: '',
        image_ref: '',
      });

    const handleSubmit = async () => {
      const payloadErrors = getPayloadErrors(formData);

      if (payloadErrors.length > 0) {
        Swal.fire({
          title: 'Error',
          html: payloadErrors.join('<br>'),
          icon: 'error',
          confirmButtonText: 'Ok'
        });
        return;
      };

      const proceed = await Swal.fire({
        title: 'Continue?',
        text: 'All data given seems to be correct. Would you like to proceed to payment?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Proceed'
      });

      if (!proceed.isConfirmed)
        return;

      state.loading = true;
      const { error, data } = await postData('api/initiate-payment', formData);
      state.loading = false;

      if (error) {
        const message = error.response?.data?.message || 'Unable to process the data';
        Swal.fire('Error', message, 'error');
        return;
      }

      location.href = `${data.paymentUrl}`;
    }

    const onFileChange = async (e) => {
      const file = e.target.files[0];
      state.loading = true;
      var payload = new FormData();
      payload.append("file", file);
      const options = { headers: { 'Content-Type': 'multipart/form-data' } };
      const { data } = await postData('images', payload, options);
      state.loading = false;
      formData.identification_url = `${state.apiUrl}images/${data.reference}`;
      formData.image_ref = `${data.reference}`;
    }

    const getPayloadErrors = payload => {
      const errors = [];

      if (payload.year_of_manufacture < 2000)
        errors.push('Vehicle cannot be older than 2000');

      const nextYear = new Date().getFullYear() + 1;

      if (payload.year_of_manufacture > nextYear)
        errors.push(`Vehicle cannot be newer than ${nextYear}`);

      if (payload.bvn.length !== 11 || !/^[0-9]+$/.test(payload.bvn))
        errors.push('Invalid BVN');

      if (payload.phone_number.length !== 11 || !/^[0-9]+$/.test(payload.phone_number))
        errors.push('Invalid Phone Number');

      return errors;
    }

    return {
      state,
      formData,
      handleSubmit,
      insuranceData,
      onFileChange
    }
  }
}
</script>